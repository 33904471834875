import flatpickr from "flatpickr";
import tippy from "tippy.js";
import ApexCharts from "apexcharts";
import dayjs from "dayjs";
import Sortable from "sortablejs";
import IMask from "imask";
import Mark from "mark.js";
import Tagify from "@yaireo/tagify";
import vNotify from "vanilla-notify/vanilla-notify";

import "./axios";
import "./pdf-viewer";
import "./data-tables";
import "./general-scripts";
import "vanilla-notify/src/vanilla-notify";

import quarterOfYear from "dayjs/plugin/quarterOfYear";

dayjs.extend(quarterOfYear);

import { v4 as uuidv4 } from "uuid";
window.uuid = uuidv4;
window.Sortable = Sortable;
window.dayjs = dayjs;
window.vNotify = vNotify;
window.$iMask = IMask;
window.$iMark = Mark;
window.Tagify = Tagify;
window.broadcastChannel = new BroadcastChannel("core-crm-channel");
window.flatpickr = flatpickr;
window.ApexCharts = ApexCharts;
window.customEvent = (eventName, payload) => {
    document.dispatchEvent(
        new CustomEvent(eventName, {
            detail: payload,
            bubbles: true,
        }),
    );
};

tippy("[data-tippy-content]", {
    allowHTML: true,
});
